var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TButton',{staticClass:"mb-3",attrs:{"content":"Create a new support post","to":_vm.lodash.getReferenceLink('support','new'),"icon":"cil-plus"}}),_c('TTableAdvance',{staticClass:"table-custom",attrs:{"items":_vm.list,"fields":_vm.fields,"store":"helper.supports","resource":"supports","enterable":"","reloadable":"","deletable":""},on:{"click-clear-filter":_vm.clearFilter,"click-reload":_vm.filterChange},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex flex-wrap align-items-baseline"},[_c('CBadge',{staticClass:"d-flex",attrs:{"color":"info"}},[_vm._v(" #"),_c('TMessageText',{attrs:{"value":item.id}})],1),_c('CBadge',{staticClass:"mx-1",attrs:{"color":"primary"}},[_c('TMessageDateTime',{attrs:{"content":item.created_at,"dateOnly":""}})],1),(_vm.checkSeen(item.logs))?_c('CBadge',{attrs:{"color":"success"}},[_c('TMessage',{attrs:{"content":"Seen"}})],1):_c('CBadge',{attrs:{"color":"danger"}},[_c('TMessage',{attrs:{"content":"Not seen"}})],1)],1),_c('TMessageText',{staticClass:"h5 mb-1",attrs:{"value":item.title,"messageOptions":{ truncate: 2 }}}),_c('TMessage',{attrs:{"content":item.description,"noTranslate":"","description":"","italic":"","truncate":2}})],1)]}},{key:"creator",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.creator)?_c('TMessageText',{attrs:{"value":item.creator.name}}):_c('TMessageText',{attrs:{"value":item.creator_id}})],1)]}},{key:"type_id",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.support_type)?_c('TMessageText',{attrs:{"value":item.support_type.name}}):_vm._e()],1)]}},{key:"deadline",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.deadline)?_c('TMessageDateTime',{attrs:{"content":item.deadline,"dateOnly":""}}):_c('TMessageNotFound')],1)]}},{key:"closed_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":item.closed_at ? 'danger' : 'success'}},[_c('TMessage',{attrs:{"content":item.closed_at ? 'Closed' : 'Active'}})],1),(item.closed_at)?_c('TMessageDateTime',{attrs:{"content":item.closed_at,"dateOnly":""}}):_vm._e()],1)]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.created_at}})],1)]}},{key:"type_id-filter",fn:function(){return [_c('SSelectSupportTypes',{attrs:{"value":_vm.filter.type_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "type_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"creator-filter",fn:function(){return [_c('SSelectCustomer',{attrs:{"value":_vm.filter.creator_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "creator_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"deadline-filter",fn:function(){return [_c('TInputDateTimeRange',{attrs:{"value":_vm.deadlineRange},on:{"update:value":[function($event){_vm.deadlineRange=$event},_vm.setDeadlineFilter]}})]},proxy:true},{key:"closed_at-filter",fn:function(){return [_c('SelectStatus',{attrs:{"value":_vm.statusFilter},on:{"update:value":[function($event){_vm.statusFilter=$event},_vm.filterChange]}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }